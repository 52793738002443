<template>
  <el-container>
    <grid-table ref="gridTable" :tableConfig="tableConfig" @selectionChange="handleSelectionChange"
      :buttonConfig="buttonConfig" @handleButtonClick="callMethod">
    </grid-table>
    <look-announcement ref="lookDialog" v-if="dialogVisible" @handleQuery="handleQuery"></look-announcement>
  </el-container>
</template>

<script>
import LookAnnouncement from "./look.vue";
export default {
  name: "sysAnnouncement",
  components: {
    LookAnnouncement,
  },
  data() {
    return {
      buttonConfig: [...(this.$route.meta.pageConfig.buttons || [])],
      tableConfig: { ...(this.$route.meta.pageConfig.table || {}) },
      selectRows: [],
      dialogVisible: false,
    };
  },
  mounted() {
    this.handleQuery();
  },
  methods: {
    handleQuery() {
      this.$refs.gridTable.fetchData();
    },
    handleSelectionChange(selection) {
      this.selectRows = selection;
    },
    callMethod(button) {
      let methods = this.$options.methods;
      const _this = this;
      methods[button.method](_this, button);
    },
    handleSearch(_this, button) {
      _this.handleQuery();
    },
    handleLook(_this, button) {
      if (!_this.selectRows || _this.selectRows.length == 0) {
        _this.$baseMessage("请选择数据", "error");
        return false;
      }
      if (_this.selectRows.length > 1) {
        _this.$baseMessage("只能选择一行", "error");
        return false;
      }
      _this.dialogVisible = true;
      _this.$nextTick(() => {
        _this.$refs.lookDialog.init(_this.selectRows[0].ID);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tree-wrapper {
  border: 1px solid #0075e3;

  .tree-title {
    height: 30px;
    background: #0075e3;
    font-size: 14px;
    line-height: 30px;
    color: white;

    i {
      font-size: 19px;
      margin: 0 5px;
      vertical-align: middle;
    }
  }
}
</style>