<template>
  <el-dialog
    title="查阅"
    :visible="visible"
    :close-on-click-modal="false"
    width="900px"
    @close="close"
  >
    <div v-html="lookContent"></div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">关闭</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { submit } from "@/api/base";

export default {
  name: "LookAnnouncement",
  data() {
    return {
      visible: false,
      lookContent: "",
    };
  },
  methods: {
    init(id) {
      this.$nextTick(async () => {
        const { data } = await submit("/api/announcement/getSysInfo", {
          id: id,
          type: 1,
        });
        this.lookContent = data.Content;
      });
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
